<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-02-18 16:53:40
 * @LastEditors: ------
 * @LastEditTime: 2022-02-18 17:06:23
-->
<template>
  <div>
    <h4 v-for="item of num" :key="item.num">{{ item.name }}</h4>
    <h4 v-for="(item, key) in obj" :key="key">{{ key }}:{{ item }}</h4>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      obj: "njkjfhnsjk",
      num: [
        {
          name: "大",
          num: 0,
        },
        {
          name: "中",
          num: 1,
        },
        {
          name: "小",
          num: 2,
        },
      ],
    };
  },
};
</script>

<style scoped lang="less"></style>
